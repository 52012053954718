/* eslint-disable react/no-array-index-key */
import { Dialog } from '@reach/dialog';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

import './KitchenItemColor.styl';

import ButtonBack from '../components/ButtonBack/index';
import ButtonKitchen from '../components/ButtonKitchen';
import DialogContent from '../components/DialogContent';
import HomeButton from '../components/HomeButton';
import Image from '../components/Image';
import MainLayout from '../layouts/MainLayout';

const KitchenItemColor = ({
    data,
    pageContext: { buttonsPaths, kitchenColorSlug, kitchenPath, kitchenSlug },
}) => {
    const img = data.file.childImageSharp.fixed;
    const { elements } = data;
    const [showDialog, setShowDialog] = useState(false);
    const [frame, setFrame] = useState(null);

    const open = (newFrame) => {
        setFrame(newFrame);
        setShowDialog(true);
    };

    const close = () => {
        setShowDialog(false);
        setFrame(null);
    };

    return (
        <MainLayout hasNoHeader>
            <div className='kitchen-item-color'>
                <Image
                    fixed={img}
                    // objectFit='contain'
                    // style={{ height: '100%' }}
                    // backgroundColor='#2d2d2d'
                    alt={kitchenSlug + kitchenColorSlug}
                />
                <ButtonBack isLink link={kitchenPath} />
                <HomeButton />
                <div className='colors-bloc'>
                    {buttonsPaths.map((item) => (
                        <ButtonKitchen
                            key={`${kitchenSlug}-button-color-${item.color}`}
                            bgColor={item.hexa}
                            label={item.color}
                            link={item.path}
                            isLink
                        />
                    ))}
                </div>
                {elements.edges.map((item, i) => (
                    <button
                        key={`zone-i-${i}`}
                        className='touch'
                        aria-label='open'
                        type='button'
                        onClick={() => open(item.node)}
                        style={{
                            left: `${item.node.position_x_y[0]}px`,
                            top: `${item.node.position_x_y[1]}px`,
                        }}
                    />
                ))}
            </div>
            <Dialog
                isOpen={showDialog}
                onDismiss={close}
                aria-label='Modal élément de cuisine'
            >
                {showDialog ? (
                    <DialogContent close={close} frame={frame} />
                ) : null}
            </Dialog>
        </MainLayout>
    );
};

KitchenItemColor.propTypes = {
    data: PropTypes.object.isRequired,
    pageContext: PropTypes.shape({
        buttonsPaths: PropTypes.array.isRequired,
        kitchenColorImage: PropTypes.string.isRequired,
        kitchenColorPath: PropTypes.string.isRequired,
        kitchenColorSlug: PropTypes.string.isRequired,
        kitchenPath: PropTypes.string.isRequired,
        kitchenSlug: PropTypes.string.isRequired,
    }).isRequired,
};

export default KitchenItemColor;

export const query = graphql`
    query($kitchenColorImage: String!, $kitchenColorSlug: String!) {
        file(name: { eq: $kitchenColorImage }) {
            ...ImageFullScreen
        }
        elements: allElementsCsv(
            filter: { color_slug: { eq: $kitchenColorSlug } }
        ) {
            edges {
                node {
                    position_x_y
                    attributes
                    attributes_icons
                    image
                    sticker_top
                    sticker_bottom
                    elem_title
                    fields {
                        imageSrc {
                            ...ImageDialog
                        }
                    }
                }
            }
        }
    }
`;
