import PropTypes from 'prop-types';
import React from 'react';

import garantie10 from '../../../images/stickers/garantie-10.svg';
import garantie15 from '../../../images/stickers/garantie-15.svg';
import garantie2 from '../../../images/stickers/garantie-2.svg';
import garantie25 from '../../../images/stickers/garantie-25.svg';
import garantie5 from '../../../images/stickers/garantie-5.svg';

import './StickerGuarantee.styl';

function StickerGuarantee({ type }) {
    const images = {
        'garantie-10': garantie10,
        'garantie-15': garantie15,
        'garantie-2': garantie2,
        'garantie-25': garantie25,
        'garantie-5': garantie5,
    };

    return (
        <div className='garantie-bloc'>
            <div className='garantie'>
                <img src={images[type]} alt={`garantie-${type}`} />
            </div>
        </div>
    );
}

StickerGuarantee.propTypes = {
    type: PropTypes.string.isRequired,
};

export default StickerGuarantee;
