import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const Content = ({ frame }) => (
    <div className='left'>
        <div className='title'>
            {frame.elem_title.map((item) => (
                <Fragment key={`elem-${item}`}>
                    <span>{item}</span>
                    <br />
                </Fragment>
            ))}
        </div>
        <div className='attributes'>
            {frame.attributes.map((attr, i) => (
                <div className='attr-item' key={`attr-${attr}`}>
                    <div>
                        <i
                            className={`icon icon-${frame.attributes_icons[i]}`}
                        />
                    </div>
                    <span>{attr}</span>
                </div>
            ))}
        </div>
    </div>
);

Content.propTypes = {
    frame: PropTypes.object.isRequired,
};

export default Content;
