import PropTypes from 'prop-types';
import React from 'react';

import './DialogContent.styl';
import Image from '../Image';
import StickerGuarantee from '../StickerGuarantee';
// import StickerStock from '../StickerStock';
import Content from './Content';

const DialogContent = ({ close, frame }) => {
    const img = frame.fields.imageSrc.childImageSharp.fixed;

    return (
        <div className='dialog-container'>
            <div className='content'>
                <Content frame={frame} />
                <div className='right'>
                    <Image fixed={img} alt='cuisine-element-' />
                </div>
            </div>
            <button
                className='close-dialog'
                onClick={close}
                aria-label='close'
                type='button'
            >
                <span aria-hidden>×</span>
            </button>
            {/* <StickerStock type={frame.sticker_top} position='top' /> */}
            <StickerGuarantee type={frame.sticker_bottom} />
        </div>
    );
};

DialogContent.propTypes = {
    close: PropTypes.func.isRequired,
    frame: PropTypes.object.isRequired,
};

export default DialogContent;
