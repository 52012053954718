import cn from 'clsx';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import './ButtonKitchen.styl';

function ButtonKitchen({ bgColor, isLink, label, link }) {
    const isWhiteBG = (bg) => bg === '#ffffff' || bg === '#fafafa';

    const renderContent = () => (
        <>
            <div className='color'>{label}</div>
            <div className='icon icon-arrow' />
            <span className='icon icon-focus' />
        </>
    );

    const getCN = () => cn('color-bloc', { dark: isWhiteBG(bgColor) });

    const props = {
        className: getCN(),
        style: { backgroundColor: bgColor },
    };

    if (isLink) {
        props.to = link;
    }

    return isLink ? (
        <Link {...props} activeClassName='active'>
            {renderContent()}
        </Link>
    ) : (
        <div {...props}>{renderContent()}</div>
    );
}

ButtonKitchen.defaultProps = {
    isLink: false,
    link: null,
};

ButtonKitchen.propTypes = {
    bgColor: PropTypes.string.isRequired,
    isLink: PropTypes.bool,
    label: PropTypes.string.isRequired,
    link: PropTypes.string,
};

export default ButtonKitchen;
